import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from './directus.service';

export interface AuthResponse {
  data: {
    expires: number;
    refresh_token: string;
    access_token: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<AuthResponse | null>;
  public currentUser$: Observable<AuthResponse | null>;
  private userDetails: BehaviorSubject<any> = new BehaviorSubject(null);
  public userDetails$: Observable<User>;

  private tokenRefreshSubscription: any;
  private redirectUrl: string | null = null;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    this.currentUserSubject = new BehaviorSubject<AuthResponse | null>(null);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.userDetails$ = this.userDetails.asObservable();
    this.initializeUser();
  }

  private fetchUserDetails(accessToken: string) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`
    });
    return this.http.get<{ data: any }>('https://admin.altewerkstatt-wallau.de/users/me', { headers })
      .pipe(tap(response => {
        this.userDetails.next(response.data);
      }),
      catchError(error => {
        console.error('Failed to fetch user details', error);
        return of(null);
      }));
  }

  private initializeUser() {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!environment.production) console.log('Initializing user, Refresh Token:', refreshToken);
    if (refreshToken) {
      this.refreshToken(refreshToken).subscribe({
        next: response => {
          if (response) {
            if (!environment.production) console.log('Refresh token valid, starting token refresh cycle');
            this.startTokenRefreshCycle();
          } else {
            this.router.navigate(['/customer']); // Weiterleitung bei ungültigem Token
          }
        },
        error: () => this.cleanup(),
      });
    }
  }

  public checkTokenValidityAndRefresh(): Observable<AuthResponse | null> {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
      return of(null);
    }
    return this.refreshToken(refreshToken);
  }

  
  public setRedirectUrl(url: string) {
    this.redirectUrl = url;
  }

  public getRedirectUrl(): string | null {
    return this.redirectUrl;
  }

  private refreshToken(refreshToken: string): Observable<AuthResponse | null> {
    if (!environment.production) console.log('Attempting to refresh token...');
    return this.http
      .post<AuthResponse>('https://admin.altewerkstatt-wallau.de/auth/refresh', {
        refresh_token: refreshToken,
        mode: 'json',
      })
      .pipe(
        tap(response => {
          if (!environment.production) console.log('Token refreshed successfully');
          this.storeUserData(response);
          this.currentUserSubject.next(response);
          this.fetchUserDetails(response.data.access_token).subscribe();
          
          const redirectUrl = this.getRedirectUrl() || '/private';
          this.router.navigate([redirectUrl]);
        }),
        catchError(error => {
          console.error('Refresh token failed', error);
          this.cleanup();
          return of(null); // Weitergabe von null bei einem Fehler
        }),
      );
  }

  private storeUserData(response: AuthResponse) {
    if (!environment.production) console.log('Storing user data:', response);
    localStorage.setItem('access_token', response.data.access_token);
    localStorage.setItem('refresh_token', response.data.refresh_token);
    localStorage.setItem('expires', (Date.now() + response.data.expires).toString());
    localStorage.setItem('currentUser', JSON.stringify(response));
  }

  private cleanup() {
    if (!environment.production) console.log('Cleaning up, logging out user...');
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.userDetails.next(null);
    this.redirectUrl = null;
    this.router.navigate(['/customers']);
  }

  login(username: string, password: string): Observable<AuthResponse | null> {
    if (!environment.production) console.log('Attempting login for user:', username);
    return this.http.post<AuthResponse>('https://admin.altewerkstatt-wallau.de/auth/login', { email: username, password: password }).pipe(
      tap(response => {
        if (!environment.production) console.log('Login successful');
        this.storeUserData(response);
        this.currentUserSubject.next(response);
        this.fetchUserDetails(response.data.access_token).subscribe();
        this.startTokenRefreshCycle();

        const redirectUrl = this.getRedirectUrl() || '/private';
        this.router.navigate([redirectUrl]);
      }),
      catchError(error => {
        console.error('Login failed', error);
        return of(null);
      }),
    );
  }

  public getUserDetails() {
    return this.userDetails.asObservable();
  }

  private startTokenRefreshCycle() {
    if (!environment.production) console.log('Starting token refresh cycle...');
    this.tokenRefreshSubscription = timer(0, 15 * 60 * 1000)
      .pipe(switchMap(() => this.checkTokenValidityAndRefresh()))
      .subscribe();
  }

  logout(): void {
    if (!environment.production) console.log('User logout, clearing data...');
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(['/customers']);
  }
}
